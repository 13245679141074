<template>
    <div>
        <Crumbs></Crumbs>
        <div>hour</div>
    </div>
</template>
<script>
export default {
    components: {
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
}
</script>